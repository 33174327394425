
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













.breadcrumb,
[class*='breadcrumb--'] {
  @extend %list-nostyle;

  display: flex;
  margin-top: $spacing * 4;

  // @include mq(m) {
  //   margin-top: $spacing * 7.5;
  // }
  margin-bottom: $spacing;

  .section-hero & {
    margin-top: $spacing * 4;

    @include mq($until: m) {
      margin-left: $spacing;
    }

    @include mq($until: l) {
      margin-top: 0;
    }
  }

  .hero & {
    margin-top: 0;
    margin-bottom: $spacing * 2;

    @include mq(l) {
      margin-top: $spacing * 2;
    }
  }
}

.breadcrumb__item {
  position: relative;

  & + & {
    margin-left: $spacing;

    &::before {
      @include center-y;

      content: '';
      left: -1rem;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-right: 1px solid $gray-lighter;
      border-bottom: 1px solid $gray-lighter;
      transform: rotate(-45deg) translate(-50%, -50%);
    }
  }

  &:last-child {
    a {
      color: $c-pink-medium;
      pointer-events: none;
    }
  }
}

.breadcrumb__item__link {
  @extend %fw-normal;

  color: $gray-lighter;
  font-size: 1.1rem;
  text-decoration: none;
  transition: color 0.2s $ease-out;

  &:hover {
    color: $c-blue-dark;

    .hero & {
      color: $c-pink-medium;
    }
  }

  @include mq(m) {
    font-size: 1.4rem;
  }
}
